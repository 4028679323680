

const firebaseConfig = {
    apiKey: "AIzaSyBw9PJoTEoPkTqGs6jk6wR3q3mBMNbPJfA",
    authDomain: "the-elegant-shop.firebaseapp.com",
    projectId: "the-elegant-shop",
    storageBucket: "the-elegant-shop.appspot.com",
    messagingSenderId: "853701311589",
    appId: "1:853701311589:web:e37fdd71422eedfa0b9a8c",
    measurementId: "G-F2JV38C6F2"
  };


  export default firebaseConfig;